import Vue from "vue";
import Meta from "vue-meta";
import Router from "vue-router";
import store from "@/store";

Vue.use(Router);
Vue.use(Meta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true,
});

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "HomePage",
      component: () => import("@/views/Home.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/events",
      name: "EventsPage",
      component: () => import("@/views/Events.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/events/archive",
      name: "ArchivesPage",
      component: () => import("@/views/Archive.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/events/create/public",
      name: "NewPublicEventPage",
      component: () => import("@/views/NewPublicEvent.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/events/create/private",
      name: "NewPrivateEventPage",
      component: () => import("@/views/NewPrivateEvent.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/events/search",
      name: "SearchEventPage",
      component: () => import("@/views/Search.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/event/:eventId",
      name: "EventDetailPage",
      component: () => import("@/views/SingleEvent.vue"),
      props: true,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/settings",
      name: "OrganizerSettingsPage",
      component: () => import("@/views/OrganizerSettings.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/privacy-policies",
      name: "PrivacyPolicyPage",
      component: () => import("@/views/PrivacyPolicies.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/sponsor",
      name: "Sponsor",
      component: () => import("@/views/SponsorPage.vue"),
    },
    {
      path: "/login",
      name: "Login",
      component: () => import("@/views/LoginPage.vue"),
    },
    {
      path: "/register",
      name: "Register",
      component: () => import("@/views/RegisterPage.vue"),
    },
    {
      path: "/forgot-password",
      name: "ForgotPassword",
      component: () => import("@/views/ForgotPassword.vue"),
    },
    {
      path: "/sso/callback",
      name: "SSOCallback",
      component: () => import("@/views/SSOCallback.vue"),
    },
    {
      path: "/marketing/:code/:action",
      name: "unsubscribePage",
      component: () => import("@/views/UnsubscribePage.vue"),
      props: true,
    },
    {
      path: "/password-reset",
      name: "newPassword",
      component: () => import("@/views/NewPassword.vue"),
      props: true,
    },
    {
      path: "/verify-account",
      name: "verifyAccount",
      component: () => import("@/views/AccountVerification.vue"),
      props: true,
    },
    {
      path: "/verify-success",
      name: "verifySuccess",
      component: () => import("@/views/EmailVerificationSuccess.vue"),
    },
    {
      path: "/verify-failure",
      name: "verifyFailure",
      component: () => import("@/views/EmailVerificationFailure.vue"),
    },
    {
      path: "/join/:code/:action",
      name: "JoinEvent",
      component: () => import("@/views/JoinEvent.vue"),
      props: true,
    },
    {
      path: "/room/:code",
      name: "VirtualRoom",
      component: () => import("@/views/VirtualRoom.vue"),
      props: true,
    },
    {
      path: "/p/:code",
      name: "OrganizerPublicProfile",
      component: () => import("@/views/OrganizerPublicProfile.vue"),
      props: true,
    },
    {
      path: "/p/:code/:eventId",
      name: "OrganizerPublicProfileSingleEvent",
      component: () => import("@/views/OrganizerPublicProfile.vue"),
      props: true,
    },
    {
      path: "/p/:code/ticket/:trackingId",
      name: "TicketPage",
      component: () => import("@/views/OrganizerPublicProfile.vue"),
      props: true,
    },
    {
      path: "/recordings",
      name: "Recordings",
      component: () => import("@/views/Recordings.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/notify",
      name: "Notify",
      component: () => import("@/views/Notify.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/event-groups",
      name: "EventGroups",
      component: () => import("@/views/EventGroups.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/team/members",
      name: "TeamMembers",
      component: () => import("@/views/team/TeamMembers.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/team/join/:code",
      name: "TeamJoin",
      component: () => import("@/views/team/TeamJoin.vue"),
      props: true,
    },
    {
      path: "/email/:code/:fileType",
      name: "ShowEmail",
      component: () => import("@/views/ShowEmail.vue"),
      props: true,
    },
    {
      path: "/helpdesk",
      name: "HelpDesk",
      component: () => import("@/views/helpdesk/index.vue"),
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: "it-home",
          component: () => import("@/views/helpdesk/home.vue"),
        },
        {
          path: "it-quickstart",
          component: () => import("@/views/helpdesk/quickstart.vue"),
        },
        {
          path: "it-eventi",
          component: () => import("@/views/helpdesk/eventi.vue"),
        },
        {
          path: "it-faq",
          component: () => import("@/views/helpdesk/faq.vue"),
        },
        {
          path: "it-virtualroom",
          component: () => import("@/views/helpdesk/virtualroom.vue"),
        },
        {
          path: "it-listeinvitati",
          component: () => import("@/views/helpdesk/listeinvitati.vue"),
        },
        {
          path: "it-campagneemail",
          component: () => import("@/views/helpdesk/campagneemail.vue"),
        },
        {
          path: "it-impostazioni",
          component: () => import("@/views/helpdesk/impostazioni.vue"),
        },
        {
          path: "it-manuali",
          component: () => import("@/views/helpdesk/manuals.vue"),
        },
      ],
    },
    {
      path: "/helpdesk",
      name: "HelpDeskEN",
      component: () => import("@/views/helpdesk/indexEN.vue"),
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: "it-home",
          component:() => import("@/views/helpdesk/home.vue"),
        },
        {
          path: "it-quickstart",
          component: () => import("@/views/helpdesk/quickstart.vue"),
        },
        {
          path: "it-eventi",
          component: () => import("@/views/helpdesk/eventi.vue"),
        },
        {
          path: "it-faq",
          component: () => import("@/views/helpdesk/faq.vue"),
        },
        {
          path: "it-virtualroom",
          component: () => import("@/views/helpdesk/virtualroom.vue"),
        },
        {
          path: "it-listeinvitati",
          component: () => import("@/views/helpdesk/listeinvitati.vue"),
        },
        {
          path: "it-campagneemail",
          component: () => import("@/views/helpdesk/campagneemail.vue"),
        },
        {
          path: "it-impostazioni",
          component: () => import("@/views/helpdesk/impostazioni.vue"),
        },
        {
          path: "it-manuali",
          component: () => import("@/views/helpdesk/manuals.vue"),
        },
        {
          path: "en-home",
          component: () => import("@/views/helpdesk/homeEN.vue"),
        },
        {
          path: "en-quickstart",
          component: () => import("@/views/helpdesk/quickstartEN.vue"),
        },
        {
          path: "en-eventi",
          component: () => import("@/views/helpdesk/eventiEN.vue"),
        },
        {
          path: "en-faq",
          component: () => import("@/views/helpdesk/faqEN.vue"),
        },
        {
          path: "en-virtualroom",
          component: () => import("@/views/helpdesk/virtualroomEN.vue"),
        },
        {
          path: "en-listeinvitati",
          component: () => import("@/views/helpdesk/listeinvitatiEN.vue"),
        },
        {
          path: "en-campagneemail",
          component: () => import("@/views/helpdesk/campagneemailEN.vue"),
        },
        {
          path: "en-impostazioni",
          component: () => import("@/views/helpdesk/impostazioniEN.vue"),
        },
        {
          path: "en-manuali",
          component: () => import("@/views/helpdesk/manualsEN.vue"),
        },
      ],
    },
    {
      path: "/oauthredirect",
      name: "oauthredirect",
      component: () => import("@/views/oauth.vue"),
    },
    {
      path: "/user-not-found",
      name: "UserNotFound",
      component: () => import("@/views/SSO404.vue"),
    },
    {
      path: "*",
      name: "404",
      component: () => import("@/views/404.vue"),
    },
  ],
});

const resetActivePath = () => {
  store.commit("setActivePath", "");
};
const updateActivePath = (to) => {
  if (to.path === "/" || to.path === "") {
    store.commit("setActivePath", "home");
  } else if (to.path.startsWith("/settings")) {
    store.commit("setActivePath", "settings");
  } else if (to.path === "/events") {
    store.commit("setActivePath", "events");
  } else if (to.path.startsWith("/events/create")) {
    store.commit("setActivePath", "newEvent");
  } else if (to.path === "/team/members") {
    store.commit("setActivePath", "recordings");
  } else if (to.path === "/recordings") {
    store.commit("setActivePath", "recordings");
  } else if (to.path === "/events/archive") {
    store.commit("setActivePath", "archive");
  } else if (to.path === "/notify") {
    store.commit("setActivePath", "notify");
  } else if (to.path === "/event-groups") {
    store.commit("setActivePath", "eventGroup");
  }
};

router.beforeEach((to, from, next) => {
  resetActivePath();
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      if (store.getters.ability.can("access", "dashboard")) {
        updateActivePath(to);
        next();
        return;
      }
      store.dispatch(
        "notifications/standaloneError",
        "Your account type doesn’t have access to the dashboard"
      );
    }

    next("/login");
  } else {
    updateActivePath(to);
    next();
  }
});

export default router;
