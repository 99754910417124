import { AbilityBuilder } from '@casl/ability';
import { render } from "mustache";

export default (store) => {
    const ability = store.getters.ability

    return store.subscribe((mutation) => {
      switch (mutation.type) {
      case 'REFRESH_USER':
        if(mutation.payload && mutation.payload.role && mutation.payload.abilities) {
          try {
            const stringVersion = JSON.stringify(mutation.payload.abilities)
            const renderedVersion = render(stringVersion, {user: mutation.payload})
            const arrayVersion = JSON.parse(renderedVersion);
            const { can, cannot, rules } = new AbilityBuilder();
            for(const item of arrayVersion) {
              if(item.inverted) {
                cannot(item.action, item.subject, item.conditions)
              }else {
                can(item.action, item.subject, item.conditions)
              }
            }
            ability.update(rules);
          } catch (e) {
            console.log('[Update User Permissions] ' + e.message);
            ability.update([]);
          }
        }
        break
        case 'REFRESH_TEAM':
        if(mutation.payload && mutation.payload.features) {
        }
        break
      case 'destroySession':
        ability.update([{ action: 'read', subject: 'all' }])
        break
      }
    })
  }
