import { Ability } from "@casl/ability";
import axios from "axios";
import Vue from "vue";
import Vuex from "vuex";
import abilityPlugin from "./ability";
import storage from "./storage";
import notifications from './notifications';
import router from '../router';

Vue.use(Vuex);

const store = new Vuex.Store({
  plugins: [
    storage({
      storedKeys: ["token", "sidebarOn"],
      destroyOn: ["destroySession"]
    }),
    abilityPlugin
  ],
  modules: {
    notifications
  },
  state: {
    status: "",
    token: "",
    sidebarOn: true,
    activePath: '',
    user: {},
    team: {},
    printedGuests: {}
  },
  getters: {
    printedGuests: state => state.printedGuests,
    activePath: state => state.activePath,
    sidebarOn: state => state.sidebarOn,
    isLoggedIn: state => !!state.token,
    token: state => state.token,
    authStatus: state => state.status,
    user: state => state.user,
    isExhibitor: state => state.user && state.user.role === "EXHIBITOR",
    language: state => (state.user && state.user.language ? state.user.language : "it"),
    identity: state =>
      state.team && state.team["identityOption"] ? state.team["identityOption"] : "DEFAULT",
    defaultEmail: state =>
      state.user && state.user["email"] && state.user["email"].includes("penguinpass.it")
        ? state.user["email"]
        : "no-reply@penguinpass.it",
    team: state => (state.team ? state.team : {}),
    features: state => (state.team && state.team.features ? state.team.features : {}),
    customCategories: state => (state.team && state.team.customCategories ? state.team.customCategories : []),
    memberCustomFieldMap: state => (state.team && state.team.memberCustomFieldMap ? state.team.memberCustomFieldMap : {}),
    memberCustomFieldMapArray: state => (state.team && state.team.memberCustomFieldMap ? Object.entries(state.team.memberCustomFieldMap) : []),
    ability() {
      return new Ability([]);
    },
    backgroudUrl: state => (state.user && state.user.backgroundUrl ? state.user.backgroundUrl : ""),
  },

  mutations: {
    createSession(state, session) {
      state.token = session.token;
    },
    destroySession(state) {
      state.token = null;
      state.user = {};
      state.team = {};
    },
    UPDATE_PRINTED_GUESTS(state, printedGuests) {
      state.printedGuests = Object.assign({}, state.printedGuests, printedGuests);
    },
    REFRESH_USER(state, user) {
      state.user = Object.assign({}, state.user, user);
    },
    REFRESH_TEAM(state, team) {
      state.team = Object.assign({}, state.team, team);
    },
    SET_USER_FIELD(state, userField) {
      state.user = Object.assign({}, state.user, userField);
    },
    SET_TEAM_FIELD(state, teamField) {
      state.team = Object.assign({}, state.team, teamField);
    },
    switchSidebar(state, sidebarOn) {
      state.sidebarOn = sidebarOn;
    },
    setActivePath(state, activePath) {
      state.activePath = activePath;
    },
  },
  actions: {
    login({ commit }, user) {
      return new Promise((resolve, reject) => {
        axios({ url: "/auth/organizer/login", data: user, method: "POST" })
          .then(resp => {
            if (resp.data.status === "FAILED" || resp.data.status === "PASSWORD_EXPIRED") {
              reject(resp);
            }
            const token = resp.data.token;
            if (token) {
              axios.defaults.headers.common["Authorization"] = `JWT ${token}`;
              commit("createSession", resp.data);
              resolve(token);
            } else {
              reject({ data: { message: "Unhandled error" } });
            }
          })
          .catch(err => {
            reject(err.response);
          });
      });
    },
    loginSSO({ commit }, token) {
      return new Promise((resolve, reject) => {
        try {
          axios.defaults.headers.common["Authorization"] = `JWT ${token}`;
          commit("createSession", { status: "SUCCESS", token });
          resolve(token);
        } catch (err) {
          reject(err);
        }
      });

    },
    register({ commit }, user) {
      return new Promise((resolve, reject) => {
        axios({ url: "/auth/organizer/register", data: user, method: "POST" })
          .then(resp => {
            if (resp.data.status === "FAILED") {
              reject(resp);
            }
            resolve("SUCCESS");
          })
          .catch(err => {
            reject(err.response);
          });
      });
    },
    teamJoin({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios({ url: "/auth/invitation/accept", data: payload, method: "POST" })
          .then(resp => {
            if (resp.data.status === "FAILED") {
              reject(resp);
            }
            resolve(resp.data);
          })
          .catch(err => {
            reject(err.response);
          });
      });
    },
    eventJoin({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/guest/rsvp/${data.action}`,
          data: {
            code: data.code,
            acceptedPolicies: data.acceptedPolicies,
            customFieldDataMap: data.customFieldDataMap,
            memberCustomFieldDataMap: data.memberCustomFieldDataMap,
            maxAcquaintance: data.maxAcquaintance,
            acquaintanceInfoList: data.acquaintanceInfoList,
            isUserInput: data.isUserInput
          },
          method: "POST"
        })
          .then(resp => {
            if (resp.data.status === "FAILED") {
              reject(resp);
            }
            resolve(resp.data);
          })
          .catch(err => {
            reject(err.response);
          });
      });
    },
    virtualRoomLoad({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/guest/virtual-room`,
          params: {
            code: data.code
          },
          method: "GET"
        })
          .then(resp => {
            if (resp.data.status === "FAILED") {
              reject(resp);
            }
            resolve(resp.data);
          })
          .catch(err => {
            reject(err.response);
          });
      });
    },
    unsubscribe({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/guest/marketing/${data.action}`,
          data: {
            code: data.code
          },
          method: "POST"
        })
          .then(resp => {
            if (resp.data.status === "FAILED") {
              reject(resp);
            }
            resolve(resp.data);
          })
          .catch(err => {
            reject(err.response);
          });
      });
    },
    setAcquaintance({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/guest/rsvp/acquaintance`,
          data: {
            code: data.code,
            maxAcquaintance: data.maxAcquaintance,
            acquaintanceInfoList: data.acquaintanceInfoList
          },
          method: "POST"
        })
          .then(resp => {
            if (resp.data.status === "FAILED") {
              reject(resp);
            }
            resolve(resp.data);
          })
          .catch(err => {
            reject(err.response);
          });
      });
    },
    getBadge({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/guest/badge/${data.eventId}/${data.memberId}?type=pdf`,
          method: "GET"
        })
          .then(resp => {
            if (resp.data.status === "FAILED") {
              reject(resp);
            }
            resolve(resp.data);
          })
          .catch(err => {
            reject(err.response);
          });
      });
    },
    getFairboothLogs({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/event/fairboothlogs/${data.eventId}`,
          method: "GET"
        })
          .then(resp => {
            if (resp.data.status === "FAILED") {
              reject(resp);
            }
            resolve(resp.data);
          })
          .catch(err => {
            reject(err.response);
          });
      });
    },
    getPageLogs({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/event/pagelogs/${encodeURIComponent(data.source)}`,
          method: "GET"
        })
          .then(resp => {
            if (resp.data.status === "FAILED") {
              reject(resp);
            }
            resolve(resp.data);
          })
          .catch(err => {
            reject(err.response);
          });
      });
    },
    getJoinLogs({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/event/joinlogs/${data.eventId}`,
          method: "GET"
        })
          .then(resp => {
            if (resp.data.status === "FAILED") {
              reject(resp);
            }
            resolve(resp.data);
          })
          .catch(err => {
            reject(err.response);
          });
      });
    },
    guestLogin({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios({
          method: "POST",
          url: `/api/guest/login`,
          headers: {
            "x-team-id": data.teamId,
            "source": data.source
          },
          data: {
            email: data.email,
            password: data.password,
            token: data.token
          }
        })
          .then(resp => {
            if (resp.data.status === "FAILED") {
              reject(resp);
            }
            resolve(resp.data);
          })
          .catch(err => {
            reject(err.response);
          });
      });
    },
    guestRegister({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios({
          method: "POST",
          url: `/api/guest/register`,
          headers: {
            "x-team-id": data.teamId
          },
          data: {
            email: data.email,
            password: data.password,
            name: data.name,
            surname: data.surname,
            country: data.country,
            telephone: data.telephone,
            postSignUpJoinPayload: data.postSignUpJoinPayload,
            lang: data.lang
          }
        })
          .then(resp => {
            if (resp.data.status === "FAILED") {
              reject(resp);
            }
            resolve(resp.data);
          })
          .catch(err => {
            reject(err.response);
          });
      });
    },
    guestRegisterNoPassword({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios({
          method: "POST",
          url: `/api/guest/add`,
          headers: {
            "x-team-id": data.teamId
          },
          data: {
            email: data.email,
            password: data.password,
            name: data.name,
            surname: data.surname,
            postSignUpJoinPayload: data.postSignUpJoinPayload,
          }
        })
          .then(resp => {
            if (resp.data.status === "FAILED") {
              reject(resp);
            }
            resolve(resp.data);
          })
          .catch(err => {
            reject(err.response);
          });
      });
    },
    guestProfile({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios({
          method: "GET",
          url: `/api/guest/profile`,
          headers: {
            "x-team-id": data.teamId,
            "Authorization": 'JWT ' + data.token,
            "source": data.source
          }
        })
          .then(resp => {
            if (resp.data.status === "FAILED") {
              reject(resp);
            }
            resolve(resp.data);
          })
          .catch(err => {
            reject(err.response);
          });
      });
    },
    guestResetPassword({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios({
          method: "POST",
          url: `/api/guest/password/send-link`,
          headers: {
            "x-team-id": data.teamId
          },
          data: {
            email: data.email
          }
        })
          .then(resp => {
            if (resp.data.status === "FAILED") {
              reject(resp);
            }
            resolve(resp.data);
          })
          .catch(err => {
            reject(err.response);
          });
      });
    },
    publicEventJoin({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios({
          method: "POST",
          url: `/api/guest/events/${data.eventId}/join`,
          headers: {
            "x-team-id": data.teamId,
            "Authorization": 'JWT ' + data.token
          },
          data: {
            isUserInput: data.isUserInput,
            acceptedPolicies: data.acceptedPolicies,
            customFieldDataMap: data.customFieldDataMap,
            memberCustomFieldDataMap: data.memberCustomFieldDataMap,
            maxAcquaintance: data.maxAcquaintance,
            acquaintanceInfoList: data.acquaintanceInfoList,
            source: data.source,
            registrationFormId: data.registrationFormId
          }
        })
          .then(resp => {
            if (resp.data.status === "FAILED") {
              reject(resp);
            }
            resolve(resp.data);
          })
          .catch(err => {
            reject(err.response);
          });
      });
    },
    logout({ commit }) {
      return new Promise((resolve, reject) => {
        delete axios.defaults.headers.common["Authorization"];
        commit("destroySession");
        resolve();
      });
    },
    sessionExpired({ dispatch, commit }) {
      // TODO Implement
      // dispatch('notifications/info', 'Session has been expired')
      // commit('destroySession')
      // router.push('/login')
    },
    forbiddenLogout({ dispatch }, message) {
      dispatch('notifications/error', message);
      router.push('/login');
    },
    teamPublicProfile({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios({
          method: "GET",
          url: `/api/team/profile/public/${data.id}`,
          headers: {
            "Authorization": 'JWT ' + data.token
          }
        })
          .then(resp => {
            if (resp.data.status === "FAILED") {
              reject(resp);
            }
            resolve(resp.data);
          })
          .catch(err => {
            reject(err.response);
          });
      });
    },
    teamPublicEvents({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios({
          method: "POST",
          url: `/api/team/events/public`,
          headers: {
            "x-team-id": data.teamId
          },
          data: {
            pageInfo: data.pageInfo,
            queryType: data.queryType
          }
        })
          .then(resp => {
            if (resp.data.status === "FAILED") {
              reject(resp);
            }
            resolve(resp.data);
          })
          .catch(err => {
            reject(err.response);
          });
      });
    },
    teamEvents({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios({
          method: "POST",
          url: `/api/guest/events/`,
          headers: {
            "x-team-id": data.teamId,
            "Authorization": 'JWT ' + data.token
          },
          data: {
            pageInfo: data.pageInfo,
            queryType: data.queryType
          }
        })
          .then(resp => {
            if (resp.data.status === "FAILED") {
              reject(resp);
            }
            resolve(resp.data);
          })
          .catch(err => {
            reject(err.response);
          });
      });
    },
    checkC19Test({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios({
          method: "POST",
          url: `/api/guest/events/checkC19Test/${data.id}`,
          headers: {
            "x-team-id": data.teamId,
            "Authorization": 'JWT ' + data.token
          }
        })
          .then(resp => {
            if (resp.data.status === "FAILED") {
              reject(resp);
            }
            resolve(resp.data);
          })
          .catch(err => {
            reject(err.response);
          });
      });
    },
    getTicketFromTrakingId({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios({
          method: "POST",
          url: `/api/guest/events/ticket/${data.id}`,
          headers: {
            "x-team-id": data.teamId
          }
        })
          .then(resp => {
            if (resp.data.status === "FAILED") {
              reject(resp);
            }
            resolve(resp.data);
          })
          .catch(err => {
            reject(err.response);
          });
      });
    },
    eventDetails({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios({
          method: "GET",
          url: `/api/guest/events/${data.id}`,
          headers: {
            "x-team-id": data.teamId,
            "Authorization": 'JWT ' + data.token
          }
        })
          .then(resp => {
            if (resp.data.status === "FAILED") {
              reject(resp);
            }
            resolve(resp.data);
          })
          .catch(err => {
            reject(err.response);
          });
      });
    },
    eventDetailsNoPassword({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios({
          method: "GET",
          url: `/api/guest/events/np/${data.id}`,
          headers: {
            "x-team-id": data.teamId,
          }
        })
          .then(resp => {
            if (resp.data.status === "FAILED") {
              reject(resp);
            }
            resolve(resp.data);
          })
          .catch(err => {
            reject(err.response);
          });
      });
    },
    getTicket({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios({
          url: data.trackingId ? `/guest/ticket/t/${data.trackingId}?type=pdf` : `/guest/ticket/${data.id}?type=pdf`,
          method: "GET",
          headers: {
            "x-team-id": data.teamId ? data.teamId : undefined,
            "Authorization": 'JWT ' + data.token
          }
        })
          .then(resp => {
            if (resp.data.status === "FAILED") {
              reject(resp);
            }
            resolve(resp.data);
          })
          .catch(err => {
            reject(err.response);
          });
      });
    },
    eventLike({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/guest/events/${data.id}/like`,
          method: data.action==="like"?"PUT":"DELETE",
          headers: {
            "x-team-id": data.teamId,
            "Authorization": 'JWT ' + data.token
          }
        })
          .then(resp => {
            if (resp.data.status === "FAILED") {
              reject(resp);
            }
            resolve(resp.data);
          })
          .catch(err => {
            reject(err.response);
          });
      });
    },
  }
});

export default store;
