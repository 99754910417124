<template>
  <div id="app" style="">
    <router-view />
  </div>
</template>
<script>

export default {
    computed : {
    },
    created: function () {},
    methods: {
    }
};
</script>

<style>
</style>
