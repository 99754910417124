module.exports = {
    client_security_code: '',
    csfr_token: '',
    user_id: '',
    user_name: '',
    user_email: '',
    organizer_id: '',
    web_site_root: '',
    app_settings: {
        // ... mutate state
    },
    method1: function () {
        // ... mutate state
    }
};

