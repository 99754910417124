import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink, split } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { WebSocketLink } from 'apollo-link-ws';
import { createUploadLink } from 'apollo-upload-client';
import { getMainDefinition } from 'apollo-utilities';
import { print } from 'graphql';
import Vue from 'vue';
import VueApollo from 'vue-apollo';
import introspectionQueryResultData from '../fragmentTypes.json';
import store from './store';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
})

// Install the vue plugin
Vue.use(VueApollo);

export function createClient () {
    const uploadLink = createUploadLink({ uri: (process.env.BASE_GRAPHQL_URL || 'http://localhost:7000/graphql') });

    const errorLink = onError(({ graphQLErrors, networkError, response, forward, operation }) => {
        if (graphQLErrors){
            graphQLErrors.map(({ message, locations, path }) =>
                console.log(
                    `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
                ),
            );
        }

        if (networkError) {
            console.log(`[Network error]: `, JSON.stringify(networkError));
        }
        if (response) console.log(`[Link error]: ${response}`);

        forward(operation);
    });

    const token = store.state.token
    const middlewareLink = new ApolloLink((operation, forward) => {
        operation.setContext({
            headers: {
                authorization: token ? `JWT ${token}` : null,
            }
        });
        return forward(operation);
    });

    const httpLinkAuth = errorLink.concat(middlewareLink).concat(uploadLink);

    const wsLink = new WebSocketLink({
        uri: (process.env.BASE_WS_URL || 'ws://localhost:7000/graphqlws') ,

        options: {
            reconnectionAttempts: 5,
            reconnect: true,
            connectionParams: {
                headers: {
                    authorization: token ? `JWT ${token}` : null
                }
            }
        }
    });
    const secureWs = errorLink.concat(middlewareLink).concat(wsLink);

    const link = split(
        // split based on operation type
        ({ query }) => {
            const { kind, operation } = getMainDefinition(query);
            return kind === 'OperationDefinition' && operation === 'subscription';
        },
        secureWs,
        httpLinkAuth,
    );

// apollo client setup
    return new ApolloClient({
        link: ApolloLink.from([ link ]),
        cache: new InMemoryCache({fragmentMatcher}),
        connectToDevTools: true
    });
}
// Call this in the Vue app file
export function createProvider ( router ) {
    // Create apollo client

    const client = createClient();
    // Create vue apollo provider
    return new VueApollo({
        defaultClient: client,
        defaultOptions: {
            $query: {
                fetchPolicy: 'cache-and-network',
            },
        },
        errorHandler (error) {
            console.log('Global Error handler ' + error.message);
            if (isUnauthorizedError(error)) {
                // Redirect to login page
                if (router.currentRoute.path !== '/login') {
                    router.replace({
                        path: '/login',
                        params: {
                            wantedRoute: router.currentRoute.fullPath,
                        },
                    })
                }
            } else {
                console.log(
                    '%cError',
                    'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;',
                    type, key, vm.$el,
                    error.message, '\n\n',
                    print(options.query), `\n`,
                    options,
                )
            }
        },
    })
}

// Manually call this when user log in
export async function onLogin (apolloClient, token) {
    try {
        await apolloClient.resetStore()
    } catch (e) {
        if (!isUnauthorizedError(e)) {
            console.log('%cError on cache reset (login)', 'color: orange;', e.message)
        }
    }
}

// Manually call this when user log out
export async function onLogout (apolloClient) {
    try {
        await apolloClient.resetStore()
    } catch (e) {
        if (!isUnauthorizedError(e)) {
            console.log('%cError on cache reset (logout)', 'color: orange;', e.message)
        }
    }
}

function isUnauthorizedError (error) {
    const { graphQLErrors } = error;
    return (graphQLErrors && graphQLErrors.some(e => e.message === 'Unauthorized'))
}
