import { MY_TEAM, USER_INFO } from "@/graphql";
import router from '../router';
import { createClient } from "../vue-apollo";

export const whoAmI = async (store, apollo) => {
    const jwtToken = store.getters.token;
    if(!!jwtToken){
        apollo.defaultClient = createClient();
        return await apollo.defaultClient.query({
            fetchPolicy: 'no-cache',
            query: USER_INFO
        }).then(async response => {

            if (response.errors){
                console.log(response.errors);
                store.commit('REFRESH_USER', {});
                return;
            }
            if(response.data && response.data.whoAmIUser) {
                const payload = response.data.whoAmIUser;
                store.commit('REFRESH_USER', payload);
            }

        }).then(async response => {
            return await apollo.defaultClient.query({
                fetchPolicy: 'no-cache',
                query: MY_TEAM
            })
        }).then(async response => {
            if (response.errors){
                console.log(response.errors);
                store.commit('REFRESH_TEAM', {});
                return;
            }
            if(response.data && response.data.myTeam) {
                const payload2 = response.data.myTeam;
                store.commit('REFRESH_TEAM', payload2);
            }
        }).catch(error => {
            store.dispatch('logout').then(_ => {
                    if (router.currentRoute.path !== '/login'){
                        router.replace({path:'/login'});
                    }
                    store.commit('REFRESH_USER', {});
                    store.commit('REFRESH_TEAM', {});
                });
        });
    }else {
        store.commit('REFRESH_USER', {});
        store.commit('REFRESH_TEAM', {});
    }
}